import React from "react";
import Heading from "../../Components/home/Heading";
import AboutUs from '../../Components/home/About/About';
import Team from "../../Components/home/Team/Team";
import { useTranslation } from "react-i18next";
import { news, oktay } from "../../assets";

const newsData = [
  {
    id: 1,
    imgSrc: oktay,
    date: "21 Aug",
    tags: ["Criminal Law", "Kidnapping"],
    title: "Azərbaycan Respublikası Dövlət Neft Şirkətinin “Karbamid” Zavoduna direktor təyin edilib.",
    description: "In this, Kidnapping the unlawful taking away or transportation of person against that person's will, usually to hold the person.",
    link: "/news/1"
  },
  {
    id: 2,
    imgSrc: oktay,
    date: "15 Sep",
    tags: ["Corporate Law", "Fraud"],
    title: "Azərbaycan Respublikası Dövlət Neft Şirkətinin “Karbamid” Zavoduna direktor təyin edilib.",
    description: "Corporate fraud is an illegal, dishonest activity perpetrated by individuals or companies in order to provide an advantageous financial outcome to the individual.",
    link: "/news/2"
  },
  {
    id: 1,
    imgSrc: oktay,
    date: "21 Aug",
    tags: ["Criminal Law", "Kidnapping"],
    title: "Azərbaycan Respublikası Dövlət Neft Şirkətinin “Karbamid” Zavoduna direktor təyin edilib.",
    description: "In this, Kidnapping the unlawful taking away or transportation of person against that person's will, usually to hold the person.",
    link: "/news/1"
  },
];

export default function News() {

  

  const { t, i18n } = useTranslation();
  return (
    <>
      <Heading heading={t("Xəbərlər")} title={t("Əsas")} subtitle={t("Xəbərlər")} />
      <div className="md:max-w-container max-w-containerSm mx-auto mt-5 md:mt-20 mb-20">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      {newsData.map((news) => (
        <article key={news.id} className="border p-4 rounded-lg shadow-md">
          <div className="relative">
            <img src={news.imgSrc} alt={news.title} className="w-full h-auto rounded-md" />
          </div>
          <div className="mt-4">
            <h3 className="mt-2 text-xl font-bold text-gray-800">{news.title}</h3>
            <a href={news.link} className="mt-4 inline-block text-blue-600 hover:underline">Daha çox</a>
          </div>
        </article>
      ))}
    </div>
      </div>
    </>
  );
}
