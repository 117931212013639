import React from 'react'
import Carousel from '../home/Carousel/Carousel'
import { banner } from '../../assets'
import CarouselMobile from '../home/CarouselMobile/CarouselMobile'

const Hero = () => {
  return (
   <>
  <div className='flex justify-center'>
  <div style={{backgroundImage: `url('/flame-cut.png')`, backgroundSize:'cover'}} className='hidden md:block w-[200px] h-[420px] text-gray-300 py-10 '>

   </div> 
   <div className='hidden md:block'>
    <Carousel/>
   </div>
   <div className='md:hidden'>
    <CarouselMobile/>
   </div>
   {/* <div className='flex mt-5'>
    <img className='w-[200px] h-[400px]' src={banner}></img>
   </div> */}
     <div style={{backgroundImage: `url('/flame-cut.png')`, backgroundSize:'cover'}} className='hidden md:block w-[200px] h-[420px] text-gray-300 py-10 '>

</div> 
  </div>
   </>
  )
}

export default Hero