import React, { useState } from "react";
import { footerLogo, logo, logoFooter, logoLeft } from "../../assets/index";
import { useTranslation } from "react-i18next";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './Footer.css'

const Footer = () => {
 
  const { t, i18n } = useTranslation();

  const [date, setDate] = useState(new Date());
  const [hoveredDate, setHoveredDate] = useState(null);
  const [birthdays, setBirthdays] = useState([
    // { name: 'Dilqem hj', date: '2024-10-30' },
    // { name: 'Ayşe', date: '2023-11-05' },
  ]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleMouseEnter = (date) => {
    const dateString = date.toISOString().split('T')[0];
    const birthday = birthdays.find(b => b.date === dateString);
    if (birthday) {
      setHoveredDate(birthday.name);
    }
  };

  const handleMouseLeave = () => {
    setHoveredDate(null);
  };
  return (
    <>
      <footer style={{backgroundImage: `url('/flame.png')`,}} className="bg-[#0086C9] text-gray-300 pt-10 overflow-hidden">
        <div className="max-w-container mx-auto">
          <div className="container mx-auto grid grid-cols-1 md:grid-cols-11 md:h-[520px]">
     <div
  style={{
    backgroundImage: `url('./parca.svg')`,
    backgroundRepeat: 'no-repeat',
  }}
  className="relative h-[600px] col-span-5 overflow-hidden hidden md:block"
>
  

  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com">
    <div
      className="absolute rounded-full bg-white text-red-500 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '183px',
        top: '90px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-red-500 text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
        <i className="fab fa-instagram text-2xl"></i>
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com">
    <div
      className="absolute rounded-full bg-white text-[#0086C9] opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '356px',
        top: '504px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-[#0086C9] text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
        <i className="fab fa-facebook text-2xl"></i>
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com">
    <div
      className="absolute rounded-full bg-white text-[#0086C9] opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '297px',
        top: '153px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-[#0086C9] text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
        <i className="fab fa-linkedin text-2xl"></i>
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="/">
    <div
      className="absolute rounded-full bg-white text-[#0086C9] opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '504px',
        top: '153px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-[#0086C9] text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="/">
    <div
      className="absolute rounded-full bg-white text-[#0086C9] opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '504px',
        top: '360px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-[#0086C9] text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com">
    <div
      className="absolute rounded-full bg-white text-red-500 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '284px',
        top: '328px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-red-500 text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
        <i className="fab fa-youtube text-2xl"></i>
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="/">
    <div
      className="absolute rounded-full bg-white text-red-500 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '4px',
        top: '156px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-red-500 text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="/">
    <div
      className="absolute rounded-full bg-white text-red-500 opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '314px',
        top: '402px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-red-500 text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
      
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com">
    <div
      className="absolute rounded-full bg-white text-[#0086C9] opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '6px', 
        top: '362px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-[#0086C9] text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
        <i  className="fab fa-twitter text-2xl"></i>
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="/">
    <div
      className="absolute rounded-full bg-white text-[black] opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '173px',
        top: '223px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-[black] text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
        {/* <i className="fab fa-tiktok text-2xl"></i> */}
      </span>
    </div>
  </a>

  <a target="_blank" rel="noopener noreferrer" href="/">
    <div
      className="absolute rounded-full bg-white text-[black] opacity-0 hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
      style={{
        left: '343px',
        top: '223px',
        width: '30px',
        height: '30px',
        animation: 'blink 0.5s infinite alternate',
      }}
    >
      <span
        className="absolute left-1/2 transform -translate-x-1/2 bg-white text-[black] text-lg font-semibold px-4 py-2 rounded-lg opacity-0 hover:opacity-100 transition-opacity duration-300"
        style={{
          minWidth: '150px', // Daha geniş bir kutu için minimum genişlik ayarı
          textAlign: 'center' // Yazıyı ortalamak için
        }}
      >
      </span>
    </div>
  </a>
  <style jsx>{`
    @keyframes blink {
      0% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  `}</style>
</div>
<div className="flex col-span-3 flex-col items-center md:items-start p-3 justify-center">
  <div className="w-full max-w-xs"> {/* Boyutları burada ayarlıyoruz */}
    <div className="flex flex-col items-center md:items-start p-3 col-span-3 bg-[#F1F1F1]">
      <Calendar
        onChange={handleDateChange}
        value={date}
        tileClassName={({ date }) => birthdays.some(b => b.date === date.toISOString().split('T')[0]) ? 'bg-red-500 text-white' : null}
        onMouseOver={({ date }) => handleMouseEnter(date)}
        onMouseOut={handleMouseLeave}
        tileContent={({ date }) => {
          const dateString = date.toISOString().split('T')[0];
          const birthday = birthdays.find(b => b.date === dateString);
          return birthday ? <div>{birthday.name}</div> : null;
        }}
      />
    </div>
  </div>
  {hoveredDate && (
    <div className="ml-5 p-4 border border-gray-300 bg-gray-100 rounded shadow-md">
      <p className="font-semibold">Doğum Günü Olan Kişi: {hoveredDate}</p>
    </div>
  )}
</div>

            <div className="flex flex-col items-center justify-center md:items-start col-span-3">
            <div className="p-3 bg-[#F1F1F1]">
            <iframe src="https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d5758.9481313843635!2d49.579261!3d40.599781!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDDCsDM1JzU4LjUiTiA0OcKwMzQnNDkuNiJF!5e1!3m2!1sen!2saz!4v1729839624495!5m2!1sen!2saz"  width="316" 
  height="280" 
  style={{border:'0'}}
  allowfullscreen="" 
  loading="lazy" 
  referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            </div>
          </div>
          <div className="md:container mx-auto text-center mt-12 border-t border-gray-700 pt-2 flex flex-col md:flex-row justify-between items-center">
            <div className="flex flex-col items-center md:items-start">
              <img src={logoFooter} alt="Socar MMC Logo" className="mb-6 w-40 " />
              {/* <div className="flex space-x-8 mt-8">
                <a href="https://www.instagram.com" className="text-[white] hover:text-blue-400 transition duration-300"><i className="fab fa-instagram text-2xl"></i></a>
                <a href="#" className="text-[white] hover:text-blue-400 transition duration-300"><i className="fab fa-facebook text-2xl"></i></a>
                <a href="#" className="text-[white] hover:text-blue-400 transition duration-300"><i className="fab fa-linkedin text-2xl"></i></a>
                <a href="#" className="text-[white] hover:text-blue-400 transition duration-300"><i className="fab fa-youtube text-2xl"></i></a>
                <a href="#" className="text-[white] hover:text-blue-400 transition duration-300"><i className="fab fa-twitter text-2xl"></i></a>
                <a href="#" className="text-[white] hover:text-blue-400 transition duration-300"><i className="fab fa-tiktok text-2xl"></i></a>
              </div> */}
            </div>
                   
            <div className="flex justify-center items-center md:h-44 h-10">
            <p>{t("2024 © Müəllif hüquqları qorunur.")}</p>
            </div>
          </div>

          
        </div>
      </footer>
    </>
  );
};

export default Footer;
