import React, { useEffect, useState } from "react";
import { useTranslation, initReactI18next } from "react-i18next";
import { azflag, engflag, rusflag } from '../../assets/index';
import i18n from "i18next";
import enTranslation from "../i18n/en/translation.json";
import azTranslation from "../i18n/az/translation.json";
import rusTranslation from "../i18n/rus/translation.json";
import axios from "axios";

const languageOptions = [
  { value: "en", label: "EN", flag: engflag },
  { value: "az", label: "AZ", flag: azflag },
  { value: "rus", label: "RU", flag: rusflag },
];

i18n.use(initReactI18next).init({
  resources: {
      en: { translation: enTranslation }, 
      az: { translation: azTranslation },
      rus: { translation: rusTranslation },
  },
  lng: localStorage.getItem("language") || "az",
  fallbackLng: "az",
  interpolation: { escapeValue: false },
});

const eventDate = new Date();
eventDate.setDate(eventDate.getDate() + 5); // 5 gün ekle
eventDate.setHours(12, 0, 0, 0); // Saat 12:00'yi ayarla

const AboveHeader = () => {

  const [isInputVisible, setInputVisible] = useState(false);

  const [temperature, setTemperature] = useState(null);
  const [windSpeed, setWindSpeed] = useState(null);
  const [error, setError] = useState(null);
  const API_KEY = "3ffb45181d104a49ac462116242910"; // Burada öz API açarınızı daxil edin
  const city = "Baku"; // Şəhəri burada dəyişə bilərsiniz
  const [rates, setRates] = useState({});
  
  
  const [timeLeft, setTimeLeft] = useState({});

  // Kalan zamanı hesapla
  function calculateTimeLeft() {
    const now = new Date();
    const difference = eventDate - now;

    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);


  useEffect(() => {
    const fetchWindSpeed = async () => {
      try {
        const response = await axios.get(
          `https://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${city}`
        );
        // Küləyin sürətini almaq
        setTemperature(response.data.current.temp_c); // Hava temperaturu °C olaraq
        setWindSpeed(response.data.current.wind_kph); // Külək sürətini km/saat olaraq alır
      } catch (error) {
        setError("Hava məlumatı yüklənərkən xəta baş verdi.");
      }
    };

    fetchWindSpeed();
  }, [city, API_KEY]);


 
  const toggleInput = () => {
    setInputVisible(!isInputVisible);
  };


  const { t, i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions.find(lang => lang.value === i18n.language));

  useEffect(() => {
      const savedLanguage = localStorage.getItem("language");
      if (savedLanguage) {
          const language = languageOptions.find(lang => lang.value === savedLanguage);
          if (language) {
              setSelectedLanguage(language);
              i18n.changeLanguage(language.value);
          }
      }
  }, []);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };

  const handleLanguageChange = (language) => {
      setSelectedLanguage(language);
      setIsOpen(false);
      i18n.changeLanguage(language.value);
      localStorage.setItem("language", language.value);
  };

  

  return (
    <>
      <header className="bg-[#0086C9] text-white h-[40px] ">
        <div className="flex flex-wrap justify-between items-center mx-auto max-w-containerSm md:max-w-container">
        <div className="flex space-x-4">
            <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com" className="text-[#d62976]">
            <i className="fa-brands fa-instagram" style={{ fontSize: '20px' }}></i>

            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com" className="text-white"> 
              <i className="fa-brands fa-facebook" style={{ fontSize: '20px' }}></i>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com" className="text-red-500">
              <i className="fa-brands fa-youtube" style={{ fontSize: '20px' }}></i>
            </a>
            <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com" className="text-white">
              <i className="fa-brands fa-linkedin" style={{ fontSize: '20px' }}></i>
            </a>
            {/* <a target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com" className="text-black">
              <i className="fa-brands fa-tiktok" style={{ fontSize: '20px' }}></i>
            </a> */}
            <a target="_blank" rel="noopener noreferrer" href="https://www.twitter.com" className="text-white">
              <i className="fa-brands fa-twitter" style={{ fontSize: '20px' }}></i>
            </a>
          </div> 
          <div className="items-center justify-center bg-[#F1F1F1] h-[40px] rounded-lg hidden md:flex">
      {timeLeft.days !== undefined ? (
        <div>
        <div className="flex space-x-4 font-bold text-white">
          <div className="flex flex-col items-center justify-center bg-[#0086C9] w-[58px] h-[40px] rounded-lg shadow-lg">
            <span >{String(timeLeft.days).padStart(2, '0')}</span>
            <span className="text-[12px] uppercase">Gün</span>
          </div>
          <div className="flex flex-col items-center justify-center bg-[#0086C9] w-[58px] h-[40px] rounded-lg shadow-lg">
            <span >{String(timeLeft.hours).padStart(2, '0')}</span>
            <span className="text-[12px] uppercase">Saat</span>
          </div>
          <div className="flex flex-col items-center justify-center bg-[#0086C9] w-[58px] h-[40px] rounded-lg shadow-lg">
            <span >{String(timeLeft.minutes).padStart(2, '0')}</span>
            <span className="text-[12px] uppercase">DƏQİQƏ</span>
          </div>
          <div className="flex flex-col items-center justify-center bg-[#0086C9] w-[58px] h-[40px] rounded-lg shadow-lg">
            <span >{String(timeLeft.seconds).padStart(2, '0')}</span>
            <span className="text-[12px]">SANİYƏ</span>
          </div>
        </div>
        </div>
      ) : (
        <span className="text-xl font-semibold text-gray-700">Tədbir başladı!</span>
      )}
    </div>

          {temperature !== null && windSpeed !== null ? (
        <div className="flex md:flex">
          <div className="relative inline-flex items-center space-x-4 h-[40px] mr-10">
          <div className="ml-4 hidden md:block">
      <a href="#" onClick={toggleInput} className="p-2 text-white">
        <i className="fas fa-search"></i>
      </a>
      {isInputVisible && (
        <input
          type="text"
          placeholder="Axtaran tapar..."
          className="ml-2 p-1 text-[black] rounded"
          onBlur={() => setInputVisible(false)} // Input dışında bir yere tıklanırsa inputu kapat
        />
      )}
    </div>
            <button
              onClick={toggleDropdown}
              className="hidden md:flex items-center px-4 py-1 border rounded-full"
            >
              <span>
              <img
                src={selectedLanguage.flag}
                alt={`${selectedLanguage.label} Flag`}
                className="h-6 w-5 mr-3"
              />
            </span>
            <span className='text-[white]'>{selectedLanguage.label}</span>
            </button>

            {isOpen && (
              <div className="absolute right-0 top-10 mt-2 w-28 rounded-md shadow-lg bg-[#0086C9] ring-1 ring-black ring-opacity-5 z-50">
                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                  {languageOptions.map((language) => (
                    <button
                      key={language.value}
                      onClick={() => handleLanguageChange(language)}
                      className="flex items-center space-x-2 px-4 py-2 w-full text-left hover:bg-teal-400"
                    >
                       <img
                      src={language.flag}
                      alt={`${language.label} Flag`}
                      className="h-6 w-5"
                    />
                    <span>{language.label}</span>
                    </button> 
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center mr-8">
            <i className="fas fa-temperature-high text-[white] mr-2"></i>
            <p>{temperature} °C</p> {/* Hava temperaturu */}
          </div>
          <div className="hidden md:flex items-center">
            <i className="fas fa-wind text-[white] mr-2"></i>
            <p>{windSpeed} m/san</p> {/* Küləyin sürəti */}
          </div>
          <div className="hidden items-center ml-8 md:flex ">
          <a href="/notifications"><i className="fa-solid fa-bell" style={{ fontSize: '24px' }}></i></a>
          </div>
          
          
        </div>
      ) : (
        <p>Məlumat yüklənir...</p>
      )}
        </div>
      </header>
    </>
  );
};

export default AboveHeader;















